import http from '@/utils/request'
import qs from 'qs'

export const getTableList = (data) => {
  return http.get('/vb-mappdetail/list', { params: { ...data } })
}
export const getRecord = (data) => {
  const params = qs.stringify(data)
  return http.post('/vb-mapp/add', params)
}
export const getplan = (mobile) => {
  return http.get('/vb-mappdetail/plan', { params: { mobile: mobile } })
}

export const GetAssessmentResult = (mobile) => {
  return http.get('/vb-mappdetail/result', { params: { mobile: mobile } })
}
export const getRecodeDetail = (mobile) => {
  return http.get('/vb-mappdetail/get', { params: { mobile: mobile } })
}
export const getClassideaList = (mobile) => {
  return http.get('/vb-classidea/get', { params: { mobile: mobile } })
}
export const addClassideaList = (data) => {
  const params = qs.stringify(data)
  return http.post('/vb-classidea/add', params)
}
