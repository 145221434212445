<template>
  <div>
    <div class="serachBox">
      <div class="listBox">
        <span>评估记录列表</span>
        <button @click="add">评估计分录入</button>
      </div>
      <a-form-model :model="dataForm" ref="form" layout="inline">
        <a-form-model-item label="创建时间">
          <vb-date-picker
            v-model="dataForm.stime"
            placeholder="开始时间"
            valueFormat="YYYY-MM-DD 00:00:00"
          />-
          <vb-date-picker
            v-model="dataForm.etime"
            placeholder="结束时间"
            valueFormat="YYYY-MM-DD 23:59:59"
          />
        </a-form-model-item>
        <a-form-model-item label="孩子姓名">
          <a-input type="text" v-model="dataForm.child_name" placeholder="请输入孩子姓名"></a-input>
        </a-form-model-item>
        <a-form-model-item label="手机号">
          <a-input type="text" v-model="dataForm.mobile" placeholder="请输入家长手机号"></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-button @click="getListSearch" class="btn_high_color" style="margin-right:20px">查询</a-button>
          <a-button class="resetBtn" @click="resetForm">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-table
      bordered
      :columns="columns"
      :dataSource="tableData"
      :pagination="pagination"
      :loading="searchBtnLoading"
      :row-key="record =>record.id"
      @change="pageChange"
    >
      <span slot="operation" slot-scope="text, record" class="operationTxt">
        <a @click="detailChange(record)" style="padding-right:20px">查看评估详情</a>
        <a-button @click="editChange(record)" v-if="record.num<6">创建记录</a-button>
      </span>
    </a-table>
  </div>
</template>
<script>
import { stampToTime } from 'utils/index'
import { getTableList } from '@/api/record'
export default {
  name: 'home',
  data () {
    return {
      dataForm: {},
      searchBtnLoading: false,
      pagination: { // 分页
        current: 1,
        total: 0,
        pageSize: 10,
        showTotal: (e) => `共${e}条`
      },
      tableData: [],
      columns: [
        { title: '序号', dataIndex: 'id', width: '70px', align: 'center' },
        { title: '孩子姓名', dataIndex: 'child_name', align: 'center' },
        { title: '家长手机号', dataIndex: 'mobile', align: 'center' },
        {
          title: '评估日期',
          dataIndex: 'assess_time',
          align: 'center',
          customRender: text => {
            if (text === '0') {
              return ''
            }
            return stampToTime(text, 'YYYY-MM-DD')
          }
        },
        { title: '评估得分', dataIndex: 'score', align: 'center' },
        {
          title: '测评记录',
          dataIndex: 'num',
          align: 'center',
          customRender: text => {
            return ['', '第一次', '第二次', '第三次', '第四次', '第五次', '第六次'][text]
          }
        },
        { title: '测评老师', dataIndex: 'tea_name', align: 'center' },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: '300px',
          scopedSlots: {
            customRender: 'operation'
          }
        }
      ]
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    resetForm () {
      this.dataForm = {}
    },
    detailChange (row) {
      this.$router.push({ path: '/evaluatingDetail', query: { curIndex: 1, mobile: row.mobile } })
    },
    editChange (row) {
      this.$router.push({ path: '/evaluating', query: { curIndex: 1, mobile: row.mobile, id: row.id } })
    },
    pageChange (e) {
      this.pagination.current = e.current
      this.getList()
    },
    getListSearch () {
      this.pagination.current = 1
      this.getList()
    },
    add () {
      this.$router.push('/evaluating')
    },
    async getList () {
      this.searchBtnLoading = true
      const param = {
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
        ...this.dataForm
      }
      const res = await getTableList(param)
      if (res.code === '1') {
        this.searchBtnLoading = false
        this.tableData = res.data.list
        this.pagination.current = +res.data.page.page
        this.pagination.total = Number(res.data.page.totalCount)
      }
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .operationTxt {
  a {
    color: var(--color-tint);
  }
  .ant-btn {
    border: none;
    color: #fff;
    background: var(--color-background);
  }
}
.serachBox {
  padding: 0 0 30px;
}
.btn_high_color {
  border: none;
  color: #fff;
  background: var(--color-background);
}
.resetBtn {
  border-color: var(--color-background);
  color: var(--color-tint);
}
.listBox{border-bottom: 1px solid #E6E6E6; padding-bottom: 16px; display: flex; align-items: center; margin-bottom:29px;
span{margin-right: 20px; color: #333; font-size: 20px;}
button {
  font-size: 16px; width: 160px; height: 40px; line-height: 38px; text-align: center;color: #fff;background: #FF5858;    border-radius: 4px; border:0;cursor: pointer;
}
}
</style>
